import { combineReducers } from 'redux';

import session from './session';
import sessionOpinions from './sessionOpinions';
import sessions from './sessions';

import goal from './goal';
import goalActions from './goalActions';
import goalJournaling from './goalJournaling';
import goalSessions from './goalSessions';
import goals from './goals';

import registrationSlice from '../features/registration/registrationSlice';
import userSettingsSlice from '../features/user/userSettingsSlice';
import searchUser from './searchUser';
import user from './user';
import userActions from './userActions';
import userLockedContentFeedbacks from './userLockedContentFeedbacks';
import userSettings from './userSettings';
import users from './users';

import profile from './profile';
import profileActions from './profileActions';
import profileGoals from './profileGoals';
import profilePrograms from './profilePrograms';
import profileSessions from './profileSessions';
import profileSkills from './profileSkills';

import notStartedPrograms from '../features/program/notStartedProgramsSlice';
import programJournalingSlice from '../features/program/programJournalingSlice';
import programMentorsSlice from '../features/program/programMentorsSlice';
import programsSlice from '../features/program/programsSlice';
import userProgramNewSkillsSlice from '../features/program/userProgramNewSkillsSlice';
import userProgramNewSlice from '../features/program/userProgramNewSlice';
import userProgramSlice from '../features/program/userProgramSlice';
import program from './program';
import programActions from './programActions';
import programJournaling from './programJournaling';
import programSearch from './programSearch';
import programV2 from './programV2';
import programs from './programs';
import programsRatings from './programsRatings';

import article from './article';
import articleOpinions from './articleOpinions';

import analyticInsights from '../features/admin/AnalyticInsights/analyticInsightsSlice';
import analyticProgram from '../features/admin/AnalyticProgram/analyticProgramSlice';
import analyticSession from '../features/admin/AnalyticSession/analyticSessionSlice';
import onboardingMessage from '../features/welcome/onboardingMessageSlice';
import welcomeMessage from '../features/welcome/welcomeMessageSlice';
import admin from './admin';
import analytics from './analytics';
import coaches from './coaches';
import contact from './contact';
import data from './data';
import feedback from './feedback';
import mentors from './mentors';
import needs from './needs';
import notes from './notes';
import toastr from './toastr';

import confirmationSlice from '../features/partner/confirmation/confirmationSlice';

import insightByIdSlice from '../features/insights/insightByIdSlice';
import insightsSlice from '../features/insights/insightsSlice';

import ProgramDefiniteEventSlice from '../features/program/ProgramsTabs/ProgramDefiniteEventSlice';
import programEventsSlice from '../features/program/ProgramsTabs/ProgramEventsSlice';
import programStepsSlice from '../features/program/ProgramsTabs/ProgramStepsSlice';
import sessionSlice from '../features/session/SessionSlice';
import sessionsAllSlice from '../features/session/SessionsAllSlice';
import mentionQuestionsSlice from '../features/session/mentionQuestionsSlice';
import sessionDetailDescriptionSlice from '../features/session/modalSessionDetail/sessionDetailDescriptionSlice';

import { apiSlice } from '../api/apiSlice';
import actionSlice from '../features/actions/actionSlice';

export default combineReducers({
  session,
  sessions,
  sessionOpinions,

  goal,
  goalSessions,
  goalActions,
  goalJournaling,
  goals,

  user,
  users,
  userActions,
  userSettings,
  userSettingsSlice,
  userLockedContentFeedbacks,
  searchUser,
  registrationSlice,

  profile,
  profileSessions,
  profileSkills,
  profileGoals,
  profileActions,
  profilePrograms,

  programsSlice,
  userProgramSlice,
  userProgramNewSlice,
  userProgramNewSkillsSlice,
  programJournalingSlice,
  programMentorsSlice,
  programs,
  program,
  programActions,
  programJournaling,
  programsRatings,
  programSearch,
  programV2,
  notStartedPrograms,

  article,
  articleOpinions,

  welcomeMessage,
  onboardingMessage,
  needs,
  coaches,
  admin,
  contact,
  feedback,
  data,
  analytics,
  analyticProgram,
  analyticInsights,
  analyticSession,
  toastr,
  notes,
  mentors,

  confirmationSlice,

  insightsSlice,
  insightByIdSlice,
  sessionSlice,
  sessionsAllSlice,
  sessionDetailDescriptionSlice,
  mentionQuestionsSlice,
  programStepsSlice,
  programEventsSlice,
  ProgramDefiniteEventSlice,

  actionSlice,

  [apiSlice.reducerPath]: apiSlice.reducer,
});
