const apiUrl = process.env.REACT_APP_API_URL;

export const apiRoutes = {
  SHOW_SESSION_API: id => `${apiUrl}/sessions/${id}`,
  FIND_SESSION_BY_ID_API: id => `${apiUrl}/sessions-by-id/${id}`,
  SEND_REPORT_SESSION_API: id => `${apiUrl}/sessions/${id}/send-report`,
  SET_FAVORITE_SESSION_API: id => `${apiUrl}/sessions/${id}/favorite/`,
  SEND_RECOMMENDATION_SESSION_API: `${apiUrl}/sessions-recommendation`,
  SAVE_CARD_SESSION_API: `${apiUrl}/sessions-save-card`,
  INDEX_SESSIONS_API: `${apiUrl}/sessions`,
  INDEX_SESSIONS_FORHOME_API: `${apiUrl}/sessions-home`,
  INDEX_SESSIONS_FAVORITES_API: userId =>
    `${apiUrl}/sessions-favorites/${userId}`,
  INDEX_SESSIONS_WITHFILTER_API: `${apiUrl}/sessions/with-filter`,
  INDEX_SESSIONS_FOR_YOU_API: `${apiUrl}/sessions/for-you`,
  UPLOAD_DATA_API: `${apiUrl}/upload-data`,
  EXPORT_USERS_API: `${apiUrl}/export-users`,
  // EXPORT_USERS_API: `${apiUrl}/analytic/user/export`,
  FILTER_BY_NEED_SESSIONS_API: needId =>
    `${apiUrl}/sessions?by_need_id=${needId}`,
  INDEX_NEEDS_API: `${apiUrl}/needs`,
  EXCLUDE_FORYOU_NEEDS_API: `${apiUrl}/needs/excludeforyou`,
  INDEX_NEEDS_WITHFILTER_API: `${apiUrl}/needs/with-filter`,
  SHOW_GOAL_API: id => `${apiUrl}/goals/${id}`,
  CREATE_GOAL_API: `${apiUrl}/goals`,
  INDEX_GOALS_API: `${apiUrl}/goals`,
  INDEX_FROM_GOAL_JOURNALING_API: id => `${apiUrl}/goals/${id}/journaling`,
  CREATE_ENTRY_FOR_GOAL_JOURNALING_API: id => `${apiUrl}/goals/${id}/journaling`,
  CREATE_ENTRY_FOR_USER_JOURNALING_API: `${apiUrl}/user/journaling`,
  EVENT_LOG_API: `${apiUrl}/event-logs`,
  UPDATE_ENTRY_JOURNALING_API: id => `${apiUrl}/journaling/${id}`,
  DELETE_ENTRY_JOURNALING_API: id => `${apiUrl}/journaling/${id}`,
  UPDATE_GOAL_API: id => `${apiUrl}/goals/${id}`,
  DELETE_GOAL_API: id => `${apiUrl}/goals/${id}`,
  INDEX_FROM_USER_ACTIONS_API: `${apiUrl}/user-actions`,
  UPDATE_FROM_USER_ACTIONS_API: actionId =>
    `${apiUrl}/user-actions/${actionId}`,
  UPDATE_ALL_FROM_USER_ACTIONS_API: `${apiUrl}/user-actions`,
  INDEX_FROM_GOAL_ACTIONS_API: id => `${apiUrl}/goals/${id}/actions`,
  UPDATE_FROM_GOAL_ACTIONS_API: (id, actionId) =>
    `${apiUrl}/goals/${id}/actions/${actionId}`,
  UPDATE_ALL_FROM_GOAL_ACTIONS_API: id => `${apiUrl}/goals/${id}/actions`,
  DELETE_ACTIONS_API: id => `${apiUrl}/actions/${id}`,
  INDEX_COACHES_API: `${apiUrl}/coaches`,
  LOGIN_ADMIN_API: `${apiUrl}/admin/login`,
  LOGIN_USER_API: `${apiUrl}/auth/local/`,
  SIGNUP_USER_API: `${apiUrl}/auth/local/register`,
  GET_ONGOING_PROGRAMS_API: `${apiUrl}/user-program-answers`,
  GET_USER_PROFILE_API: `${apiUrl}/users/me`,
  SHOW_USER_API: `${apiUrl}/users/detail`,
  INDEX_USERS_API: `${apiUrl}/users`,
  CREATE_USER_API: `${apiUrl}/users`,
  UPDATE_USER_API: id => `${apiUrl}/users/${id}`,
  DELETE_USER_API: id => `${apiUrl}/users/${id}`,
  SEARCH_USER: params => `${apiUrl}/users/search/items?${params}`,
  EDIT_PASSWORD_USER_API: `${apiUrl}/auth/reset-password`,
  RESET_PASSWORD_USER_API: id => `${apiUrl}/users/${id}/password`,
  RECOVER_PASSWORD_USER_API: `${apiUrl}/auth/forgot-password`,
  INVITE_USER_API: id => `${apiUrl}/users/${id}/invite`,
  UPDATE_PROFILE_API: `${apiUrl}/users/me`,
  UPLOAD_PICTURE_PROFILE_API: `${apiUrl}/profile/picture`,
  SHOW_PROFILE_API: `${apiUrl}/users/me/`,
  SHOW_PROFILE_SESSIONS_API: `${apiUrl}/profile/sessions`,
  SHOW_PROFILE_SKILLS_API: `${apiUrl}/profile/skills`,
  SHOW_PROFILE_GOALS_API: `${apiUrl}/profile/goals`,
  SHOW_PROFILE_ACTIONS_API: `${apiUrl}/profile/actions`,
  SEND_CONTACT_API: `${apiUrl}/contact`,
  // SEND_MESSAGE_HOME_API: `${apiUrl}/contact-home`,
  SEND_MESSAGE_HOME_API: `${apiUrl}/contact`,
  SEND_FEEDBACK_API: `${apiUrl}/feedback`,
  ANALYTICS_API: `${apiUrl}/analytics`,
  ANALYTICS_FILTERS_API: `${apiUrl}/analytics/filters`,
  INDEX_FROM_NOTIFICATIONS: `${apiUrl}/notifications`,
  INDEX_FROM_USER_NOTIFICATIONS: `${apiUrl}/user-notifications`,
  UPDATE_USER_NOTIFICATIONS: id => `${apiUrl}/user-notifications/${id}`,
  CREATE_USER_NOTIFICATIONS: `${apiUrl}/user-notifications`,
  SHOW_REWARDS_API: query => `${apiUrl}/rewards?${query}`,
  INDEX_REWARDS_API: `${apiUrl}/rewards`,
  SESSION_OPINIONS_API: `${apiUrl}/session-feedbacks`,
  SEND_SESSION_OPINION_API: `${apiUrl}/session-feedbacks`,
  EXPORT_SESSION_OPINIONS_API: `${apiUrl}/export-session-opinions`,
  WITH_FILTERS_NEEDS_API: `${apiUrl}/needs/with-filter`,
  INDEX_FILTERS_API: `${apiUrl}/filters`,
  SHOW_ARTICLE_API: id => `${apiUrl}/articles/${id}`,
  INDEX_PROGRAMS_API: `${apiUrl}/programs`,
  SHOW_PROGRAM_API: id => `${apiUrl}/programs/${id}`,
  CREATE_PROGRAM_API: `${apiUrl}/user-program-answers`,
  UPDATE_PROGRAM_API: id => `${apiUrl}/programs/${id}`,
  SHOW_USER_PROGRAM_API: id => `${apiUrl}/programs-user/${id}`,
  GET_PROGRAM_ACTIONS_API: id => `${apiUrl}/user-program-answers/${id}/activities`,
  INDEX_FROM_PROGRAM_JOURNALING_API: id => `${apiUrl}/programs/${id}/journaling`,
  UPDATE_PROGRAM_MODAL: `${apiUrl}/programs/modal`,
  MARK_AS_DONE_FROM_USER_ACTIONS_API: `${apiUrl}/user/actionsmarkasdone`,
  MARK_ACTIVITY_AS_COMPLETE_API: id => `${apiUrl}/user-activity-answers/${id}/complete`,
  SHOW_ACTIVITY_ANSWER_API: id => `${apiUrl}/user-activity-answers/activity/${id}`,
  SEND_RECOMMENDATION_PROGRAM_API: `${apiUrl}/programs/recommendation`,
  DELETE_PROGRAM_API: id => `${apiUrl}/programs/${id}`,
  SHOW_PROFILE_PROGRAMS_API: `${apiUrl}/profile/programs`,
  PROGRAM_MENTOR_API: `${apiUrl}/mentor/program`,
  PROGRAM_MENTORS_API: id => `${apiUrl}/mentor/program?user_program_id=${id}`,
  GOAL_MENTOR_API: `${apiUrl}/mentor/goal`,
  GOAL_MENTORS_API: id => `${apiUrl}/mentor/goal?goal_id=${id}`,
  CREATE_ENTRY_FOR_PROGRAM_JOURNALING_API: id =>
    `${apiUrl}/userprograms/${id}/journaling`,
  INDEX_NOTES_API: id => `${apiUrl}/goals/${id}/notes`,
  CREATE_NOTE_API: id => `${apiUrl}/goals/${id}/notes`,
  UPDATE_NOTE_API: (goalId, id) => `${apiUrl}/goals/${goalId}/notes/${id}`,
  DELETE_NOTE_API: (goalId, id) => `${apiUrl}/goals/${goalId}/notes/${id}`,
  SEND_ARTICLE_OPINION_API: `${apiUrl}/user/article_opinions`,
  ARTICLE_OPINIONS_API: `${apiUrl}/article_opinions`,
  EXPORT_ARTICLE_OPINIONS_API: `${apiUrl}/export-article-opinions`,
  SEND_PROGRAM_RATING_API: `${apiUrl}/program-ratings`,
  PROGRAM_RATINGS_API: `${apiUrl}/program-ratings`,
  PROGRAM_SEARCH_API: query => `${apiUrl}/program/search?${query}`,
  PROGRAM_API: id => `${apiUrl}/program/?id=${id}`,
  EXPORT_PROGRAMS_RATINGS_API: `${apiUrl}/export-programs-ratings`,
  USER_LOCKED_CONTENT_FEEDBACKS_API: `${apiUrl}/user/locked-content-feedbacks`,
  USER_LOCKED_CONTENT_FEEDBACK_STATUS_API: query => `${apiUrl}/user/locked-content-feedbacks/status?${query}`,
  GET_USER_SETTINGS_API: `${apiUrl}/user-setting/me`
};

export const pageRoutes = {
  LANDING_PATH: "/",
  WELCOME_PAGE: '/welcome',
  WELCOME_NEW_PROGRAM_PAGE: (id) => `/welcome/new-program/${id}`,
  HOME_PATH: "/home",
  PRIVACY_PATH: '/privacy',
  SESSION_PATH: (id, goalId = null) => {
    if (goalId) {
      return `/sessions/${id}?goal_id=${goalId}`;
    }
    return `/sessions/${id}/`;
  },
  GOAL_PATH: id => `/goals/${id}`,
  USER_PATH: id => `/admin/users/${id}`,
  // Dynamic routes - see https://github.com/zeit/next.js/issues/2833#issuecomment-414919347
  DYNAMIC_SESSION_PATH: (id, goalId = null) => {
    if (goalId) {
      return `/sessions/${id}?goal_id=${goalId}`;
    }
    return `/sessions/${id}`;
  },
  DYNAMIC_PROGRAM_PATH: (id) => {
    return `/programs/${id}`;
  },
  DYNAMIC_GOAL_PATH: id => `/goals/${id}`,
  DYNAMIC_USER_PATH: id => `/admin/users/${id}`,
  NEW_PROGRAM_PATH: id => `/new-program/${id}/`,
  NEW_GOAL_PATH: `/new-goal/`,
  LOGIN_ADMIN_PATH: "/admin/login",
  LOGIN_USER_PATH: "/login",
  PROFILE_USER_PATH: "/profile",
  DASHBOARD_ADMIN_PATH: "/admin/dashboard",
  SESSIONS_LIBRARY_PATH: `/sessions-library`,
  PROGRAMS_LIBRARY: `/programs-library`,
  USER_PROGRAM_PATH: id => id ? `/programs/${id}` : `/programs-library`,
  PROGRAM_CHALLENGE_PATH: (actionId, programId) => `/programs/${programId}/challenge/${actionId}`,
  NEW_CHALLENGE_PATH: "/challenge/create",
  ARTICLE_PATH: (id, programId = null) => {
    if (programId) {
      return `/articles/${id}?program_id=${programId}`;
    }
    return `/articles/${id}`;
  },
  INSIGHTS_PAGE: '/insights',
  ADD_AN_INSIGHTS: `/insights/add-an-insight`,
  INSIGHT_DETAILS_PAGE: (id = null) => {
    if(id) {
     return `/insights/insight-details?id=${id}`;
    }
    return `/insights/insight-details`;
  }
};
