import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { createGlobalStyle } from 'styled-components';
import media, { mediaMax } from './mixins/media';
export default createGlobalStyle`
  /*
  ==========================================================================
    Document
  ==========================================================================

  1. Stretch <html> stretch to fill our screen height
  2. Make children of html (body) occupy at least 100% of the screen
  3. Viewport is scalable and occupies at least 375px (iPhone 6)
  */

  html {
    min-height: 100%;
    display: flex;
    min-width: 310px;
    background-color: ${(props) => props.theme.colors.pampas2};
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  .html-no-scroll{
    height:100%;
    overflow-y:hidden;
  }

  /*
  ==========================================================================
    Body
  ==========================================================================

  1. Force scroll always to prevent scrollbars to appear/disappear based on the page contents
  2. Make sure that we occupy 100% of our parent and allow our child elements to do the same
  3. Needed for IE11 otherwise flex wouldn't grow vertically, see https://stackoverflow.com/a/42930574
  */

  html {
    ${mediaMax.sm`
        --background-color: #242424;
    `}
  }
  @keyframes loading {
    0% {
      background-position: 50% 0;
    }
    100% {
      background-position: -150% 0;
    }
  }
  .loading{
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 10%, #f6f7f8 20%, #f6f7f8 100%) !important;
    background-size: 200% 100% !important;
    animation: loading 1.5s linear infinite;
    opacity: 0.65 !important;
    color: transparent !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
    z-index: 3;
  }
  .lumia-bg{
  background: conic-gradient(from -77.82deg at 50% 50%, #FE6BD8 -0.08deg, #0069D6 10.08deg, #018CBD 54.8deg, #00A4FF 89deg, #00AEDE 110.38deg, #01C490 132.7deg, #00E4FF 160.85deg, #D56573 177.72deg, #DB705D 195.76deg, #ED8E22 234.95deg, #FFC82D 303.65deg, #E8D99E 324.2deg, #FE6BD8 359.92deg, #0069D6 370.08deg);
  }

  body {
    overflow-x: hidden;
    display: flex;
    flex: 1 0 auto;
    margin: 0;
    flex-direction: column;
    font-family: ${(props) => props.theme.utils.WorkSans()};
    font-size: ${(props) => props.theme.typography.browserContext};
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: relative;
    text-rendering: optimizeLegibility;
    overflow-wrap: break-word; /* Break long words by default */
    background-color: ${(props) => props.theme.colors.fantasy};
    min-height: 100vh;
  }

  /* ==========================================================================
    Page transitions
  ========================================================================== */

  .page-transition-enter {
    opacity: 0;
  }

  .page-transition-enter-active {
    opacity: 1;
    transition: opacity ${(props) => props.theme.animations.slow}ms;
  }

  .page-transition-exit {
    opacity: 1;
  }

  .page-transition-exit-active {
    opacity: 0;
    transition: opacity ${(props) => props.theme.animations.slow}ms;
  }

  /* ==========================================================================
    Headings
  ========================================================================== */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  /* ==========================================================================
    Anchors
  ========================================================================== */

  a,
  a:focus,
  a:visited,
  a:hover,
  a:active {
    display: flex;
    outline: none !important;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none !important;
  }

  a[href^="tel"] {
    color: inherit;
  }

  /* ==========================================================================
    Inputs
  ========================================================================== */

  input {
    border-radius: 0;
    padding: 0;
    caret-color: ${(props) => props.theme.colors.persimmon};
  }

  textarea {
    resize: none;
    caret-color: ${(props) => props.theme.colors.persimmon};
  }

  select::-ms-expand {
    display: none;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  /* input:not([value=""]) {
    border-color: ${(props) => props.theme.colors.white};
  } */

  input:hover {
    border-color: ${(props) => props.theme.colors.white};
  }

  @keyframes autofill {
    to {
      background: transparent;
    }
  }

  input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
    -webkit-text-fill-color: ${(props) => props.theme.colors.white};
  }

  ::placeholder {
    /* Most modern browsers support this now. */
    color: ${(props) => props.theme.colors.manatee};
  }

  /* ==========================================================================
    Overrides
  ========================================================================== */

  .ant-popover-arrow {
    display: none !important;
  }

  .ant-tag-blue {
    cursor: pointer;
    color: #fc6453;
    background: #fff4f0;
    border-color: #fc6453;
  }

  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1001;
  }

  .ant-modal-wrap.bottom-left-position {
    top: auto;
    right: auto;
    left: 30px;
    bottom: 30px;
    margin: 0;
    overflow: initial;
    ${mediaMax.sm`
        right: 12px;
        left: 12px;
        bottom: 8px;
      }
    `}

    .ant-modal {
      top: 0;
      padding-bottom: 0;
    }
  }

  .ant-carousel .slick-track,
  .ant-carousel .slick-slide {
    outline: none;
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto !important;

    > div {
      height: 100%;
    }
  }

  .carousel-overflow-visible {
    .slick-list {
      overflow: visible;
    }
  }

  .note-popover{
    ${mediaMax.sm`
      bottom: 0;
      padding-top: 0;
      top: unset !important;
      position: fixed;
      left: 0;
      transform-origin: unset !important;
      height: 214px;
      .ant-popover-content{
        height: 100%;
        .ant-popover-inner{
          height: 214px;
          border-radius: 16px 16px 0px 0px;
          background: #FCFAFB;
        }
      }
      .ant-popover-inner-content{
        width: 100vw;
        padding: 16px 24px;
      }
    `}
    ${media.md`
      width: 248px;
      .ant-popover-inner-content{
        padding: 36px 26px;
      }

    `}
  }
  .ant-calendar-footer-show-ok{

.ant-calendar-time-picker-btn,
.ant-calendar-today-btn{
color:black!important;
cursor: pointer;
}
.ant-calendar-time-picker-btn{color:#fc6453!important;}
  }

  /* ==========================================================================
    Others
  ========================================================================== */

  .fade-in {
    animation: fadein 1s;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  .fade-in-scale {
    animation: fadeinScale 0.5s;
  }

  @keyframes fadeinScale {
    from { opacity: 0; transform: scale(0) }
    to   { opacity: 1; transform: scale(1) }
  }

  .d-flex {
    display: flex;
  }

  .justify-center {
    justify-content: center;
  }

  .flex-col {
    flex-direction: column;
  }

  .position-fixed {
    position: fixed;
  }

  .position-relative {
    position: relative;
  }

  .text-center {
    text-align: center;
  }

  .primary-color {
    color: #fc6453;
  }

  .ant-tag-blue {
    cursor: pointer;
    color: #fc6453;
    background: #fff4f0;
    border-color: #fc6453;
  }

  #top-grid-wrapper {
    &.sessions-final {
      height: 100%;
      width: 100%;
      position: initial;
      background-color: white;
      ${mediaMax.md`
        background-color: #F8F4EE;
      `}
      ${mediaMax.sm`
        margin-bottom: 80px;
      `}
      #centered-portal {
        ${mediaMax.sm`
          height: 100vh;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
        `}
      }
    }
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .no-transform {
    transform: none !important;
  }

  .full-height {
    height: 100%;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hide-sm {
    ${mediaMax.sm`
      display: none !important;
    `}
  }

  .hide-md {
    ${mediaMax.md`
      display: none !important;
    `}
  }

  .responsive-media {
    position: relative;
    padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
    height: 0;
    overflow: hidden;
    box-shadow: 0px 40.2797px 59.6737px rgb(0 0 0 / 7%), 0px 14.7028px 21.7819px rgb(0 0 0 / 5%), 0px 7.13792px 10.5747px rgb(0 0 0 / 4%), 0px 3.49914px 5.18391px rgb(0 0 0 / 3%), 0px 1.38357px 2.04973px rgb(0 0 0 / 2%);
    border-radius: 6px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .locked-overlay {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .bottom-positioned-modal{
    ${mediaMax.sm`
      &:before{
        vertical-align: bottom;
      }
    `}
  }

  .flex-col-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-row-start {
    display: flex;
    align-items: center;
  }

  .mt-22 {
    margin-top: 22px;
  }

  .hidden-text {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .line-clamp-2 {
    -webkit-line-clamp: 2;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
`;
